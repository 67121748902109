<template>
  <div>
    <div class="pt-3 px-3 pb-10 flex-container d-flex flex-column">
      <ThePageTitle hideBackButton>Standing Order</ThePageTitle>

      <div class="mt-3 mb-3 text-center">
        <v-icon color="green" size="90">mdi-check-circle</v-icon>
        <p class="my-0 text-center">Standing order complete</p>
      </div>

      <p class="text-body-1 my-0 grey--text">Summary</p>
      <v-card outlined class="grey lighten-3 rounded-md">
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div class="mb-3">
              <p class="text-caption my-0 font-weight-light">Account name</p>
              <p class="text-body-1 mt-n1">{{ $store.state.paymentDetails.BankAccountName }}</p>
            </div>
            <div class="mb-3">
              <p class="text-caption my-0 font-weight-light">Payment reference</p>
              <p class="text-body-1 my-0 font-weight-bold">{{$store.state.requestID}}</p>
            </div>
          </div>
          <div class="mt-n3 d-flex justify-space-between text-body-1">
            <p class="my-0 font-weight-black">Amount</p>
            <p class="my-0 font-weight-black">£{{ $store.state.paymentDetailsToSubmit.total }}</p>
          </div>
          <v-divider class="my-1"></v-divider>
          <div class="d-flex justify-space-between">
            <p class="text-caption my-0 font-weight-light">Frequency</p>
            <p class="text-caption my-0 font-weight-light">{{ $store.state.paymentDetails.Request.Frequency }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="text-caption my-0 font-weight-light">First payment</p>
            <p class="text-caption my-0 font-weight-light">{{$store.state.paymentDetailsToSubmit.initialPayment}}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="text-caption my-0 font-weight-light">Final payment</p>
            <p class="text-caption my-0 font-weight-light">{{$store.state.paymentDetailsToSubmit.finalPayment}}</p>
          </div>
        </v-card-text>
      </v-card>

      <div class="text-center mt-5">
        <div class="mb-3">
          <p class="text-caption my-0 font-weight-light">Payment reference</p>
          <p class="text-body-1 my-0 font-weight-bold">{{$store.state.requestID}}</p>
        </div>
        <p class="text-caption">{{new Date().toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}}</p>
      </div>

      <v-btn to="/" color="primary" x-large text class="mt-sm-8">home</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "StandingOrderSuccess",
  data() {
    return {};
  },
  methods: {
    closeAppWindow() {
      window.close();
    },
  },
};
</script>

<style scoped>
</style>
